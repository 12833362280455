import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  axisLabelStyle,
  chartLabelsStyle,
  fontInter,
  returnChartColors,
  theme,
  tooltipColors,
} from "../../theme";
import {
  numberFormatter,
  labelNumberFormatter,
  numberFormatterWithoutDecimals,
} from "../../utils/dataFormatters";
import { returnFileName } from "../../utils/file-name-helper";
import {
  getUniqueArrByKey,
  lineChartDataFormatter,
} from "../../utils/CommonKPIResponseFormatterHelper";
import { ChartProps } from "../../Types/ChartProps";
import { colorPickerHelper } from "../../utils/color-picker-helper";

const returnChartData = (data: any, metaData: any) => {
  let finalSeries: any = [];
  Object.keys(data).forEach((el: string) => {
    if (el == "column") {
      let obj: any = {};
      let temp = data[el];
      temp.forEach((element: any) => {
        obj[element.primary_name] = obj[element.primary_name] || {};
        obj[element.primary_name].data = obj[element.primary_name].data || [];
        obj[element.primary_name].name = element.primary_name;
        obj[element.primary_name].data = [
          ...obj[element.primary_name].data,
          element.value,
        ];
        obj[element.primary_name].color =
          metaData?.chart?.misc?.colors?.[element.primary_name] || "";
      });

      Object.keys(obj).forEach((el) => {
        finalSeries.push(obj[el]);
      });
    } else if (el === "line") {
      let obj: any = {};
      obj["name"] = obj["name"] = metaData?.chart?.["line-chart-name"];
      obj["type"] = "spline";
      obj["yAxis"] = 1;
      obj["data"] = lineChartDataFormatter(data[el]);
      obj["color"] =
        metaData?.chart?.misc?.colors?.line || returnChartColors(0);
      finalSeries = [...finalSeries, obj];
    }
  });

  return finalSeries;
};

const GroupedColumnStackedAndLineChart: React.FC<ChartProps> = (chartData) => {
  let data: any = chartData.chartData;
  let meta_data = chartData.chartMeta;
  let data_type = meta_data?.chart?.currencytype;
  let date_range = chartData.dateRange;
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const height = chartData.height;
  const width = chartData.width;
  const editorMode = chartData.editorMode;
  let disableDataLabels = meta_data.chart["data-label-disabled"] || false;
  let removeDecimal = meta_data.chart["remove-decimal"] || false;

  const options = {
    chart: {
      type: "column",
      zoomType: editorMode ? "" : "xy",
      events: {
        render: function () {
          chartData.kpiRendered(true);
          chartData.chartReference(chartComponentRef);
          if (chartData.customKPI === false) {
            chartComponentRef.current?.chart.reflow();
          }
        },
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      title: {
        text: meta_data.chart?.["x-axis-text"] || "",
        style: axisLabelStyle,
        margin: 20,
      },
      categories: getUniqueArrByKey(data["column"], "secondary_name"),
      labels: {
        formatter: function (this: any) {
          return `<span style="${chartLabelsStyle}">${this.value}</span>`;
        },
      },
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          formatter: function (this: any) {
            return `<span style="${chartLabelsStyle}">${labelNumberFormatter(
              this.value,
              meta_data.chart?.columncurrencytype || "",
              true
            )}</span>`;
          },
        },
        title: {
          text: meta_data.chart?.["primary-y-axis-text"] || "",
          style: axisLabelStyle,
        },
      },
      {
        // Secondary yAxis
        labels: {
          formatter: function (this: any) {
            return `<span style="${chartLabelsStyle}"> ${labelNumberFormatter(
              this.value,
              meta_data.chart?.linecurrencytype || "",
              true
            )}</span>`;
          },
        },
        title: {
          text: meta_data.chart?.["secondary-y-axis-text"] || "",
          style: axisLabelStyle,
        },
        gridLineWidth: 1,
        opposite: true,
      },
    ],
    tooltip: {
      backgroundColor: tooltipColors.bgColor,
      borderColor: tooltipColors.borderColor,
      color: tooltipColors.dataFormatColor,
      padding: 10,
      borderRadius: 5,
      borderWidth: 1,
      formatter: function (this: any) {
        if (this.series.options.type === "spline") {
          data_type = meta_data.chart?.linecurrencytype || "";
        }
        if (this.series.options.type === "column") {
          data_type = meta_data.chart?.columncurrencytype || "";
        }

        return `<span style="color: ${theme.custom.whiteColor}">${
          this.key
        }: ${labelNumberFormatter(this.y, data_type)}</span>`;
      },
    },
    plotOptions: {
      series: {
        column: {},
        dataLabels: {
          inside: true,
          enabled: !disableDataLabels,
          formatter: function (this: any) {
            if (this.series.options.type === "spline") {
              return removeDecimal
                ? numberFormatterWithoutDecimals(
                    this.total,
                    meta_data.chart?.linecurrencytype || "",
                    true
                  )
                : numberFormatter(
                    this.total,
                    meta_data.chart?.linecurrencytype || "",
                    true
                  );
            } else {
              return removeDecimal
                ? numberFormatterWithoutDecimals(
                    this.total,
                    meta_data.chart?.columncurrencytype || "",
                    true
                  )
                : numberFormatter(
                    this.total,
                    meta_data.chart?.columncurrencytype || "",
                    true
                  );
            }
          },
          style: {
            fontWeight: 500,
            fontFamily: fontInter,
          },
          color: colorPickerHelper(chartData.kpiPosition || 0),
        },
        stacking: "normal",
        shadow: false,
        borderWidth: 0,
        borderRadiusTopLeft: "10%",
        borderRadiusTopRight: "10%",
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    exporting: {
      filename: returnFileName(meta_data?.chart?.title || "", date_range),
    },
    colors: returnChartColors(data && data.length),
    credits: {
      enabled: false,
    },
    series: returnChartData(data, meta_data),
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{
        style: {
          width: "100%",
          height: "calc(100% - 1px)",
        },
      }}
      options={
        editorMode
          ? {
              ...options,
              ...{ chart: { ...options.chart, height: height, width: width } },
            }
          : options
      }
      ref={chartComponentRef}
    ></HighchartsReact>
  );
};

export default React.memo(GroupedColumnStackedAndLineChart);
