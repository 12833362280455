import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { returnColumnOrBarChartData } from "../../utils/CommonKPIResponseFormatterHelper";
import { numberFormatter, labelNumberFormatter, numberFormatterWithoutDecimals } from "../../utils/dataFormatters";
import {
  returnChartColors,
  theme,
  tooltipColors,
  chartLabelsStyle,
  axisLabelStyle,
  fontInter,
} from "../../theme";
import { returnFileName } from "../../utils/file-name-helper";
import { ChartProps } from "../../Types/ChartProps";

const ColumnAndLineChart: React.FC<ChartProps> = (chartData) => {
  let data = chartData.chartData;
  let meta_data = chartData.chartMeta;
  let data_type = meta_data?.chart?.currencytype || "$";
  let date_range = chartData.dateRange;
  const height = chartData.height;
  const width = chartData.width;
  const editorMode = chartData.editorMode;

  let disableDataLabels = meta_data.chart["data-label-disabled"] || false;

  const lineChartDataFormatter = (lineChartData: any) => {
    return lineChartData.map((ele: any) => {
      return ele.value;
    });
  };

  const returnCombinedChartsData = (responseData: any) => {
    return Object.keys(responseData).map((ele, index) => {
      let obj: any = {};
      if (ele === "column") {
        obj["name"] = meta_data?.chart?.["column-chart-name"] || "";
        obj["color"] = returnChartColors(Object.keys(responseData).length)[
          index
        ];
        obj["type"] = "column";
        obj["yAxis"] = 1;
        obj["data"] = responseData[ele]
          ? responseData[ele].map((el: any) => el.value)
          : [];
        return obj;
      } else if (ele === "line") {
        obj["name"] = meta_data?.chart?.["line-chart-name"] || "";
        obj["color"] = returnChartColors(Object.keys(responseData).length)[
          index
        ];
        obj["type"] = "spline";
        obj["data"] = lineChartDataFormatter(responseData[ele]);
        return obj;
      }
    });
  };

  const returnXAxisLabels = (responseData: any) => {
    return responseData["column"].map((ele: any) => {
      return ele["primary_name"];
    });
  };

  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const options = {
    chart: {
      type: "column",
      zoomType: editorMode ? "" : "xy",
      events: {
        render: function () {
          chartData.kpiRendered(true);
          chartData.chartReference(chartComponentRef);
          if (chartData.customKPI === false) {
            chartComponentRef.current?.chart.reflow();
          }
        },
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      title: {
        text: meta_data.chart?.["x-axis-text"] || "",
        style: axisLabelStyle,
        margin: 20,
      },
      categories: returnXAxisLabels(data),
      labels: {
        formatter: function (this: any) {
          return `<span style="${chartLabelsStyle}">${this.value}</span>`;
        },
      },
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          formatter: function (this: any) {
            return `<span style="${chartLabelsStyle}">${labelNumberFormatter(
              this.value,
              meta_data.chart?.linecurrencytype || "",
              true
            )}</span>`;
          },
        },
        title: {
          text: meta_data.chart?.["primary-y-axis-text"] || "",
          style: axisLabelStyle,
        },
      },
      {
        // Secondary yAxis
        labels: {
          formatter: function (this: any) {
            return `<span style="${chartLabelsStyle}"> ${labelNumberFormatter(
              this.value,
              meta_data.chart?.columncurrencytype || "",
              true
            )}</span>`;
          },
        },
        title: {
          text: meta_data.chart?.["secondary-y-axis-text"] || "",
          style: axisLabelStyle,
        },
        gridLineWidth: 1,
        opposite: true,
      },
    ],
    legend: {
      enabled: true,
    },
    plotOptions: {
      series: {
        dataLabels: {
          inside: true,
          enabled: !disableDataLabels,
          formatter: function (this: any) {
            if (this.series.options.type === "spline") {
              return numberFormatter(
                this.y,
                meta_data.chart?.linecurrencytype || "",
                true
              );
            } else {
              return numberFormatterWithoutDecimals(
                this.y,
                meta_data.chart?.columncurrencytype || "",
                true
              );
            }
          },
          style: {
            fontWeight: 500,
            fontFamily: fontInter,
            color: theme.custom.primaryDarkColor,
          },
        },
        borderWidth: 0,
        borderRadiusTopLeft: "10%",
        borderRadiusTopRight: "10%",
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      filename: returnFileName(meta_data?.chart?.title || "", date_range),
    },
    tooltip: {
      backgroundColor: tooltipColors.bgColor,
      borderColor: tooltipColors.borderColor,
      color: tooltipColors.dataFormatColor,
      padding: 10,
      borderRadius: 5,
      borderWidth: 1,
      headerFormat: ``,
      formatter: function (this: any) {
        if (this.series.options.type === "spline") {
          data_type = meta_data.chart?.linecurrencytype || "";
        }
        if (this.series.options.type === "column") {
          data_type = meta_data.chart?.columncurrencytype || "";
        }

        return `<span style="color: ${theme.custom.whiteColor}">${
          this.key
        }: ${labelNumberFormatter(this.y, data_type)}</span>`;
      },
    },
    series: returnCombinedChartsData(data),
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{
        style: {
          width: "100%",
          height: "calc(100% - 1px)",
        },
      }}
      options={
        editorMode
          ? {
              ...options,
              ...{ chart: { ...options.chart, height: height, width: width } },
            }
          : options
      }
      ref={chartComponentRef}
    ></HighchartsReact>
  );
};

export default React.memo(ColumnAndLineChart);
