import { Box, Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import { deleteTemplate } from "../../services/impact-report-service";
import { showMessage } from "../../store/error-handler-store";
import { useAppDispatch } from "../../store/hook";
import { theme } from "../../theme";
import ThumbnailImageContainer from "./ThumbnailImageContainer";

type Props = {
  style: any;
  template_icon: any;
  onEditTemplate: Function;
  handleSelect: any;
  templateId: string;
  templateIsMutable: boolean;
  onDeleteTemplate: Function;
  templateObj: any;
  variant?: string;
  userQuery?: string;
  audience?: string;
  purpose?: string;
  title?: string;
  pageLen?: number;
  forPanoroma?: boolean;
  extraSx?: any;
  slug?:string;
};

const TemplateThumbnailComponent = (props: Props) => {
  const { templateObj, userQuery, forPanoroma, extraSx, style } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const thumbnail = props.template_icon;
  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = () => {
    setAnchorEl(null);
    props.onEditTemplate();
  };

  const dispatch = useAppDispatch();
  const handleDelete = () => {
    setAnchorEl(null);
    if (window.confirm("Are you sure you want to delete the template?")) {
      deleteTemplate(templateObj.id).then((resp) => {
        if (resp.status === 200) {
          dispatch(
            showMessage({ message: "Deleted template", variant: "success" })
          );
          props.onDeleteTemplate();
        }
      });
    }
  };
  return (
    <Grid container direction={"column"}>
      <ThumbnailImageContainer 
        {...props} 
        open={open} 
        thumbnail= {thumbnail} 
        handleClick={handleClick} 
        anchorEl={anchorEl} 
        setAnchorEl={setAnchorEl} 
        handleEdit={handleEdit} 
        handleDelete={handleDelete}
      />
      <Box
        sx={{
          // maxWidth: "200px",
          height: "42px",
          mt: 1,
        }}
      >
        <Typography
          sx={{
            wordWrap: "break-word",
            color: theme.custom.primaryDarkColor,
          }}
          variant="poppins_p_600_14"
        >
          <div title={props.title}>
            {(props.title?.length || 0) > 38
              ? props.title?.substr(0, 38) + "..."
              : props.title}
          </div>
        </Typography>
      </Box>
    </Grid>
  );
};

export default memo(TemplateThumbnailComponent);
