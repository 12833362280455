import React, { memo } from "react";
import "../DataTableMolecule.scss";
import CustomTableCell from "../../atoms/data-table/CustomTableCell";
import { MetaDataObject } from "../../../Types/MetaDataObject";
import CustomHyperLinkCell from "../../atoms/data-table/CustomHyperLinkCell";
import CustomRangeCell from "../../atoms/data-table/CustomRangeCell";
import CustomRichTextCell from "../../atoms/data-table/CustomRichTextCell";
import DataTable from "../../atoms/DataTable";

type Props = {
  isFullscreen?: boolean;
  setFullscreen?: Function;
  chartData: any;
  chartMeta: MetaDataObject;
  kpiRendered?: React.Dispatch<React.SetStateAction<boolean>>;
  forReport?: boolean;
  drillDownConfig?: Object;
  onDataPointClick?: Function;
  slug?: string;
  pagination?: Object;
  onPaginationChange?: Function;
  onRender?: Function;
};

const GroupTable: React.FC<Props> = (chartData) => {
  let kpiData = chartData.chartData;
  let tableMeta = chartData.chartData.meta;

  let slug = chartData.slug;
  let meta_data = chartData.chartMeta;

  let onDataPointClick = chartData.onDataPointClick;
  let forImpactReport = chartData.forReport || false;

  const renderTableCell = (type: string) => {
    switch (type) {
      case "string-value":
        return CustomTableCell;

      case "structured":
        return CustomRichTextCell;
      case "range":
        return CustomRangeCell;
      case "hyperlink":
        return CustomHyperLinkCell;
      default:
        return CustomTableCell;
    }
  };

  const createColumnHelper = (dataitem: any) => {
    if (!dataitem) return [];
    for (let item of dataitem) {
      if (Object.keys(item).includes("columns")) {
        createColumnHelper(item.columns);
      } else {
        item["cell"] = ({ row, getValue, cell }: any) => {
          let val = getValue();

          let TCell: any = renderTableCell(val?.["type"] || "string-value");
          return (
            <TCell
              row={row}
              cellValue={val}
              cell={cell}
              isReport={forImpactReport}
              onCellClick={onDataPointClick}
            />
          );
        };
      }
    }
    return dataitem;
  };

  const createColumns = (r: any) => {
    return createColumnHelper(r);
  };
  const columns = React.useMemo(() => createColumns(tableMeta), [kpiData]);

  const data = React.useMemo(() => (kpiData ? kpiData.data : []), [kpiData]);

  return (
    <div className="group_table_wrapper">
      <DataTable
        isFullscreen={chartData.isFullscreen || false}
        setFullscreen={() => {
          if (chartData.setFullscreen) {
            return chartData.setFullscreen(false);
          }
        }}
        collapsed={meta_data?.chart?.collapsed || false}
        columns={columns}
        onRender={chartData?.onRender}
        data={data}
        forImpactReport={forImpactReport}
        isGroupTable={true}
      />
    </div>
  );
};

export default memo(GroupTable);
