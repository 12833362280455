import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import BackIconCircular from "../../../assets/back_icon_circular.png";
import BackIconCircularDark from "../../../assets/back_icon_circular_dark.png";
import HamburgerRound from "../../../assets/hamburger_round.png";
import { useMediaAndOrientation } from "../../../hooks/useMediaAndOrientation";

type Props = {
  pages: any[];
  setCurrentPageIndex: Function;
  currentPageIndex: number;
  isThemeDark: boolean;
};

const PresentationLeftPanel = ({
  setCurrentPageIndex,
  currentPageIndex,
  pages,
  ...props
}: Props) => {
  const {isMobileScreen} = useMediaAndOrientation();
  const [minimized, setMinimized] = useState(isMobileScreen || false);
  const mobileSpecificPostion = {position:"fixed",
                                  zIndex: 60000,
                                };
  const renderMinimized = () => {
    return (
      <Grid container 
        ml={{md: 6.5}} 
        mt={{md:6.5}}
        sx={{ 
        ...isMobileScreen && {...mobileSpecificPostion, width : "20%"},
        }}>
        <img
          src={HamburgerRound}
          height={40}
          width={40}
          style={{ cursor: "pointer" }}
          onClick={() => setMinimized(false)}
        />
      </Grid>
    );
  };

  const renderMaximized = () => {
    return (
      <Stack
        ml={{md: 3}}
        width={isMobileScreen? '100%' : 260}
        height={'90vh'}
        p={1.5}
        sx={{
          backgroundColor: props.isThemeDark ? "#4D4D4D" : "#E2E2E2",
          color: props.isThemeDark ? "#fff" : "inherit",
          borderRadius: "6px",
          ...isMobileScreen && mobileSpecificPostion
        }}
        flexDirection={'column'}
        justifyContent= 'flex-start'
        alignItems={'start'}
      >
        <Grid xs={12} pl={2} pt={2}>
          <img
            src={props.isThemeDark ? BackIconCircularDark : BackIconCircular}
            height={40}
            width={40}
            style={{ cursor: "pointer" }}
            onClick={() => setMinimized(true)}
          />
        </Grid>
        <Grid width={'100%'} height={'100%'} sx={{overflowY: 'auto'}}>
          <List
            sx={{
              "& .MuiListItemButton-root.Mui-selected": {
                backgroundColor: props.isThemeDark ? "#6D6D6D" : "#CFCFCF",
              },
            }}
          >
            {pages.map((page) => (
              <ListItem key={page.pageIndex} disableGutters>
                <ListItemButton
                  sx={{ mr: 1 }}
                  selected={page.pageIndex === currentPageIndex}
                  onClick={() => {
                    setCurrentPageIndex(page.pageIndex);
                    isMobileScreen && setMinimized(true);
                  }}
                >
                  <Typography variant="inter_400_14">
                    {page.pageIndex + 1} &nbsp;{" "}
                    {page.pageTitle || `Page ${page.pageIndex + 1}`}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Stack>
    );
  };
  return <>{minimized ? renderMinimized() : renderMaximized()}</>;
};

export default PresentationLeftPanel;
