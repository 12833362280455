import {
  Divider,
  Link,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useCallback, useRef, useMemo } from "react";
import CopyElementIcon from "../../../assets/copy_element.png";
import ElementDeleteIcon from "../../../assets/delete_red.png";
import SendBackIcon from "../../../assets/move_back.png";
import SendBackwardsIcon from "../../../assets/move_backward.png";
import BringForwardsIcon from "../../../assets/move_forward.png";
import BringFrontIcon from "../../../assets/move_front.png";
import SetBackgroundIcon from "../../../assets/set_background.png";
import CopyToAbovePagesIcon from "../../../assets/copy-above-pages.png";
import CopyToBelowPagesIcon from "../../../assets/copy_below_pages.png";
import CopyToAllPagesIcon from "../../../assets/copy-all-pages.png";
import { theme } from "../../../theme";
import "./Design.scss";
import EmbedEditor from "./EmbedEditor";
import IconsEditor from "./IconsEditor";
import ImageEditor from "./ImageEditor";
import KPIEditor from "./KPIEditor";
import ShapesEditor from "./ShapesEditor";
import TableEditor from "./TableEditor";
import TabularReportEditor from "./TabularReportEditor";
import TextEditor from "./TextEditor";
import VideoEditor from "./VideoEditor";
import InteractiveElementEditor from "./InteractiveElementEditor";
import GenAISkeleton from "./GenAISkeleton";
import { Instance } from "@popperjs/core";
import { linkExcludedTypes } from "../../../services/impact-report-service";

function Element(props: any) {
  const {
    reportUID,
    isTemplateEdit,
    element,
    actionSource,
    updateElement,
    deleteElement,
    addElement,
    updateZIndex,
    pageId,
    toggleCustomAbles,
    onLoad,
    isPublic,
    keepRatio,
    copyElement,
    duplicateElement,
    isElementSelected,
    isElementOnMove,
    pasteElement,
    updatePage,
    clearClipBoard,
    source,
    pageHeight,
    selectedElementID,
    pageWidth,
    zoom,
    isPresentation,
    repeatOnAllPages,
    pagesLength,
    pageIndex
  } = props;
  const { transparency, padding } = element;

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  let cursorPosition = useRef({ left: 0, top: 0 });
  const _updateElement = useCallback(
    (data: object, action = "user") => {
      updateElement(element.id, data, pageId, selectedElementID, action);
    },
    [updateElement, element.id, pageId, selectedElementID]
  );
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const _deleteElement = useCallback(
    (id) => {
      deleteElement(id, element.pageId);
    },
    [deleteElement, element.id, pageId, selectedElementID]
  );

  const getFilterSetting = (element: any) => {
    let _filters =
      (element?.attributes.blur ? " blur(10px) " : "") +
      (element?.attributes.saturation ? " saturate(2) " : "") +
      (element?.attributes.grayscale ? " grayscale(100%) " : "") +
      (element?.attributes.sepia ? " sepia(2) " : "");
    if (element?.opacity) {
      _filters = `${_filters} opacity(${element?.opacity}%)`;
    }

    return _filters;
  };

  const _updateBackground = () => {
    _deleteElement(element.id);
    updatePage(pageId, {
      attributes: {
        backgroundImage: `url(${element?.attributes?.url})`,
        filter: getFilterSetting(element),
      },
    });
  };

  const addTextElement = (
    e: any,
    { delta, attributes }: any,
    dnd: boolean = true
  ) => {
    if (
      (cursorPosition.current.left <= 0 || cursorPosition.current.top <= 0) &&
      dnd
    )
      return;

    const element = {
      type: "TextEditor",
      transparency: 1,
      zIndex: 0,
      posX: 100,
      posY: 100,
      rotate: 0,
      scale: 1,
      delta: delta,
      attributes: { ...attributes, color: "#000000", onLoadDelta: delta },
    };

    addElement(
      element,
      {
        left: cursorPosition.current?.left,
        top: cursorPosition.current?.top,
      },
      "drop",
      "",
      dnd
    );
  };
  const memoizedAttributes = useMemo(()=>element.attributes, [JSON.stringify(element.attributes)])

  const pickElementByType = () => {
    if (reportUID && element.textPrompt && !isTemplateEdit) {
      return (
        <GenAISkeleton
          prompt={element.textPrompt}
          height={element.height}
          width={element.width}
          reportUID={reportUID}
          type={element.type}
          updateElement={_updateElement}
          extraData={{ delta: element.delta }}
          attributes={memoizedAttributes}
        />
      );
    }
    switch (element.type) {
      case "TextEditor":
        return (
          <TextEditor
            selectedSelf={selectedElementID && element.id === selectedElementID}
            id={element.id}
            attributes={memoizedAttributes}
            height={element?.height}
            width={element?.width}
            pageWidth={pageWidth}
            isPublic={isPublic}
            ops={element?.ops}
            element={element}
            duplicateElement={duplicateElement}
            deleteElement={_deleteElement}
            updateElement={_updateElement}
            isElementOnMove={isElementOnMove}
            actionSource={actionSource}
            scaleValue={element?.scale || 1}
          />
        );
      case "ImageEditor":
        return (
          <ImageEditor
            toggleCustomAbles={toggleCustomAbles}
            attributes={memoizedAttributes}
            alt={element.alt}
            elementHeight={element.height}
            elementWidth={element.width}
            elementBorderRadius={element.borderRadius}
            elementClipPath={element.clipPath}
            updateElement={_updateElement}
          />
        );
      case "VideoEditor":
        return (
          <VideoEditor
            isElementSelected={isElementSelected}
            attributes={memoizedAttributes}
            alt={element.alt}
            height={element.height}
            width={element.width}
            updateElement={_updateElement}
          />
        );
      case "EmbedEditor":
        return (
          <EmbedEditor
            isElementSelected={isElementSelected}
            attributes={memoizedAttributes}
            height={element.height}
            width={element.width}
            alt={element.alt}
            updateElement={_updateElement}
          />
        );
      case "KPIEditor":
        return (
          <KPIEditor
            key={`kpi_editor_${pageId}_${element.id}`}
            attributes={memoizedAttributes}
            height={element.height}
            width={element.width}
            keepRatio={keepRatio}
            addTextElement={addTextElement}
            updateElement={_updateElement}
            isElementOnMove={isElementOnMove}
            onLoad={onLoad}
            isPublic={isPublic}
            selectedSelf={element.id === selectedElementID}
            element={element}
            duplicateElement={duplicateElement}
            deleteElement={_deleteElement}
            scaleValue={element?.scale}
            source={source}
            zoom={zoom}
          />
        );
      case "TableEditor":
        return (
          <TableEditor
            attributes={memoizedAttributes}
            id={element.id}
            height={element.height}
            selectedElementID={selectedElementID}
            width={element.width}
            pageHeight={pageHeight}
            pageWidth={pageWidth}
            scaleValue={element?.scale || 1}
            tableConfig={element.tableConfig || {}}
            elementPadding={element.padding}
            updateElement={_updateElement}
            onLoad={onLoad}
            isPublic={isPublic}
            isPresentation={isPresentation}
          />
        );
      case "ShapesEditor":
        return (
          <ShapesEditor element={element} updateElement={_updateElement} />
        );
      case "InteractiveElementEditor":
        return (
          <InteractiveElementEditor
            element={element}
            updateElement={_updateElement}
            isPublic={isPublic}
          />
        );
      case "IconEditor":
        return (
          <IconsEditor
            attributes={memoizedAttributes}
            height={element?.height}
            width={element?.width}
            padding={element?.padding}
            updateElement={_updateElement}
          />
        );
      case "TabularReportEditor":
        return (
          <TabularReportEditor
            attributes={memoizedAttributes}
            firstTimeEleAddition={element.firstTimeEleAddition}
            height={element.height}
            width={element.width}
            pageHeight={pageHeight}
            pageWidth={pageWidth}
            updateElement={_updateElement}
            isPublic={isPublic}
            onLoad={onLoad}
          />
        );
      default:
        break;
    }
  };

  const handleOpen = () => {
    if (props.isPublic) {
      window.open(element?.linkurl, "_blank");
    }
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
    event.stopPropagation();
  };
  const handleClose = () => {
    setContextMenu(null);
  };

  const move = useCallback((action) => {
    updateZIndex(element.id, pageId, action);
    handleClose();
  }, []);

  const _copyElement = (item: string) => {
    copyElement(item);
    handleClose();
  };

  const _pasteElement = () => {
    pasteElement();
    handleClose();
  };

  const _repeatOnAllPages =(item: Object, type: string) =>{
    repeatOnAllPages(JSON.stringify(item), type);
    handleClose();
  }

  return (
    <div
      className="element no-cursor"
      onContextMenu={handleContextMenu}
      style={{
        height: "100%",
        width: "100%",
        opacity: transparency,
        display: "block",
        verticalAlign: "middle",
      }}
    >
      {!isPresentation &&
      isPublic &&
      element?.linkurl &&
      !linkExcludedTypes.includes(element?.type) ? (
        <Link
          target="_blank"
          href={element?.linkurl}
          style={{
            textDecorationColor: theme.custom.primaryDarkColor,
            textDecorationThickness: "1px",
            textDecorationStyle: "dotted",
            textUnderlineOffset: "min(0.2em, 10px)",
          }}
          sx={{
            "&:any-link": {
              color: "inherit",
            },
          }}
        >
          {pickElementByType()}
        </Link>
      ) : (
        <>{pickElementByType()}</>
      )}
      <Menu
        style={{
          zIndex: 3001,
          display: isPublic ? "none" : "block",
        }}
        sx={{
          "& .MuiMenu-paper": {
            padding: "18px 12px 18px 12px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.12)",
            borderRadius: "6px",
          },
          "& .MuiMenuItem-root": {
            margin: "6px",
          },
        }}
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={() => _copyElement(JSON.stringify(element))}>
          <ListItemIcon>
            <img
              src={CopyElementIcon}
              alt="Copy Element"
              style={{ maxHeight: "18px" }}
            />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
          <Typography>
            <kbd className={"short-command"}>⌘C</kbd>
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => _pasteElement()}>
          <ListItemIcon>
            <img
              src={CopyElementIcon}
              alt="Paste Element"
              style={{ maxHeight: "18px" }}
            />
          </ListItemIcon>
          <ListItemText>Paste</ListItemText>
          <Typography>
            <kbd className={"short-command"}>⌘V</kbd>
          </Typography>
        </MenuItem>
        <Divider sx={{ borderColor: theme.custom.menuDividerColor }} />
        <MenuItem onClick={() => _repeatOnAllPages(element,'copyAbove')} disabled={pageIndex <=1}>
          <ListItemIcon>
            <img
              src={CopyToAbovePagesIcon}
              alt="Copy element above"
              style={{ maxHeight: "18px" }}
            />
          </ListItemIcon>
          <ListItemText>Copy element above</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => _repeatOnAllPages(element, 'copyBelow')} disabled={pageIndex===pagesLength}>
          <ListItemIcon>
            <img
              src={CopyToBelowPagesIcon}
              alt="Copy element below"
              style={{ maxHeight: "18px" }}
            />
          </ListItemIcon>
          <ListItemText>Copy element below</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => _repeatOnAllPages(element, 'allPages')} disabled={pagesLength ===1} >
          <ListItemIcon>
            <img
              src={CopyToAllPagesIcon}
              alt="Repeat on all pages"
              style={{ maxHeight: "18px" }}
            />
          </ListItemIcon>
          <ListItemText>Repeat on all pages</ListItemText>
        </MenuItem>
        <Divider sx={{ borderColor: theme.custom.menuDividerColor }} />
        <MenuItem onClick={() => move("move_forwards")}>
          <ListItemIcon>
            <img
              src={BringForwardsIcon}
              alt="Delete Element"
              style={{ maxHeight: "18px" }}
            />
          </ListItemIcon>
          <ListItemText>Bring Forwards</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => move("move_backwards")}>
          <ListItemIcon>
            <img
              src={SendBackwardsIcon}
              alt="Send Backwards"
              style={{ maxHeight: "18px" }}
            />
          </ListItemIcon>
          <ListItemText>Send Backwards</ListItemText>
        </MenuItem>
        <Divider sx={{ borderColor: theme.custom.menuDividerColor }} />
        <MenuItem onClick={() => move("move_front")}>
          <ListItemIcon>
            <img
              src={BringFrontIcon}
              alt="Bring to Front"
              style={{ maxHeight: "18px" }}
            />
          </ListItemIcon>
          <ListItemText>Bring to Front</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => move("move_back")}>
          <ListItemIcon>
            <img
              src={SendBackIcon}
              alt="Send to Back"
              style={{ maxHeight: "18px" }}
            />
          </ListItemIcon>
          <ListItemText>Send to Back</ListItemText>
        </MenuItem>
        <Divider sx={{ borderColor: theme.custom.menuDividerColor }} />
        <MenuItem onClick={() => _deleteElement(element.id)}>
          <ListItemIcon>
            <img
              src={ElementDeleteIcon}
              alt="Delete"
              style={{ maxHeight: "18px" }}
            />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
          <Typography>
            <kbd className={"short-command"}>del</kbd>
          </Typography>
        </MenuItem>
        {element.type === "ImageEditor" && (
          <MenuItem onClick={() => _updateBackground()}>
            <ListItemIcon>
              <img
                src={SetBackgroundIcon}
                alt="SetBackground"
                style={{ maxHeight: "18px" }}
              />
            </ListItemIcon>
            <ListItemText>Set as background</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default React.memo(Element);
