import { Box, Button, Grid, Menu, MenuItem, Typography } from "@mui/material";

import { has, isEmpty } from "lodash";
import { theme } from "../../theme";

import TripleDot from "../../assets/fi-rr-menu-dots.png";
import PreviewTemplatePopup from "../molecules/Editor/PreviewTemplatePopup";
import ThumbnailSlider from "./ThumbnailSlider";

const ThumbnailImageContainer =(props: any) =>{
    const { templateObj, 
        userQuery, 
        forPanoroma, 
        extraSx, 
        style, 
        thumbnail, 
        handleClick, 
        anchorEl, 
        setAnchorEl, 
        handleEdit, 
        handleDelete,
        open, 
        reportItem,
        slug
    } = props;
    const isPresentation = slug?.includes('presentation');

    return (<Box
        className={"report-thumbnail-container"}
        sx={{
          height: !isEmpty(style || extraSx) && (has(style, "height") || has(extraSx, "style")) ? style?.height || extraSx?.style?.height: 240,
          border: "1px solid #e1e1e1",
          borderRadius: "6px",
          overflow: "hidden",
          boxShadow: reportItem ? "none": "0px 2px 4px 0px #00000026",
          padding: "25px 10px 0px"
        }}
      >
        {typeof thumbnail == "string" ? (
          <div style={{
            maxWidth: "100%",
            width: "100%",
            maxHeight: "100%",
            height: "100%"
          }}>
            <img
              className="report-thumbnail-image"
              src={thumbnail}
              style={{
                borderRadius: isPresentation? "0px":"12px",
                minWidth: "100%",
                width: "100%",
                height: "auto",
                // maxHeight: "240px",
              }}
              alt=""
            />
            </div>
        ) : (
          <div
            style={{
              maxWidth: "100%",
              borderRadius: "12px",
              maxHeight: !isEmpty(extraSx) && has(extraSx, "style") ? "auto" : "100%",
              display: "grid",
            }}
          >
            <ThumbnailSlider blurBackground={!reportItem} thumbnail={thumbnail} isPresentation={isPresentation}/>
          </div>
        )}
        {!reportItem && <img
          className="triple-dot-container"
          height="24px"
          width="24px"
          src={TripleDot}
          style={{
            display: props.templateIsMutable && !forPanoroma ? "block" : "none",
          }}
          onClick={(e) => handleClick(e)}
          alt="menu"
        />}
        {!reportItem && <Menu
          transformOrigin={{
            vertical: -10,
            horizontal: "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleEdit} sx={{ p: 1.5, width: "214px" }}>
            <Typography
              variant="inter_p_500_14"
              sx={{ color: `${theme.custom.primaryGreyColor} !important` }}
            >
              Edit Template
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleDelete} sx={{ p: 1.5, width: "214px" }}>
            <Typography
              variant="inter_p_500_14"
              sx={{ color: `${theme.custom.errorColor} !important` }}
            >
              Delete Template
            </Typography>
          </MenuItem>
        </Menu>}
        {!reportItem && <div className="middle">
          <Button
            sx={{ mb: 2 }}
            fullWidth
            variant="contained"
            onClick={props.handleSelect}
          >
            <Typography variant="poppins_p_600_14">Select</Typography>
          </Button>
          <Button
            sx={{
              mb: 2,
              display: props.templateIsMutable && !userQuery ? "block" : "none",
            }}
            fullWidth
            variant="outlined"
            onClick={() => props.onEditTemplate()}
          >
            <Typography variant="poppins_p_600_14">Edit</Typography>
          </Button>
          {
            <PreviewTemplatePopup
              extraSx={{
                color: theme.palette.primary.main,
                fontSize: "12px",
                cursor: "pointer",
              }}
              templateObj={templateObj}
              userQuery={userQuery}
              audience={props.audience}
              purpose={props.purpose}
            />
          }
        </div>}
        {<Box
          sx={{
            display: forPanoroma ? "none" : "block",
            borderRadius: "12px",
            backgroundColor: "#00000045",
            // maxWidth: "180px",
            px: "12px",
            py: "4px",
            position: "absolute",
            bottom: "8px",
            left: "8px",
          }}
        >
          <Typography
            sx={{
              color: theme.custom.whiteColor,
            }}
            variant="inter_500_12"
          >
            {props.pageLen || ''} Page
            {(props.pageLen || 0) > 1 ? "s" : ""}
          </Typography>
        </Box>}
      </Box>)
}

export default ThumbnailImageContainer;



