import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import { chartColors, returnChartColors, theme } from "../../theme";
import { MetaDataObject } from "../../Types/MetaDataObject";
import { NumberComponentDataFormat } from "../../Types/NumberComponentDataFormat";
import { numberFormatter } from "../../utils/dataFormatters";

interface Props {
  chartData: any;
  chartMeta: MetaDataObject;
  kpiRendered: React.Dispatch<React.SetStateAction<boolean>>;
  kpiPosition: number;
}

const DisplayNumberDataComponent: React.FC<Props> = (chartData) => {
  let data = chartData.chartData;
  let kpiPosition = chartData.kpiPosition;
  chartData.kpiRendered(true);
  return (
    <React.Fragment>
      <Grid
        sx={{
          height: "100%",
          width: "100%",
          border: `1px solid ${theme.custom.KPIMoleculeBorderColor}`,
          borderRadius: "6px",
        }}
        container
        direction="column"
        justifyContent={"space-evenly"}
      >
        {data &&
          data.length > 0 &&
          data.map((ele: NumberComponentDataFormat, index: number) => {
            return (
              <React.Fragment>
                <Grid px={3} container alignContent={"center"}>
                  <Grid item xs={1}>
                    <Box
                      mt={2}
                      sx={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "6px",
                        background:
                          data.length === 1
                            ? chartColors[kpiPosition]
                            : returnChartColors(data.length)[index],
                      }}
                    ></Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      sx={{
                        display: "block",
                        color: theme.custom.primaryDarkColor,
                        margin: 1,
                        lineHeight: "45px",
                      }}
                      variant="poppins_h5_600_18"
                    >
                      {ele?.title || ""}
                    </Typography>
                  </Grid>
                  <Grid textAlign={"right"} item xs={3}>
                    <Typography
                      sx={{
                        display: "block",
                        color: theme.custom.primaryDarkColor,
                        margin: 1,
                      }}
                      variant="poppins_h2_700_30"
                    >
                      {numberFormatter(ele?.value, ele.number_format)}
                    </Typography>
                  </Grid>
                </Grid>
                {index !== data.length - 1 && (
                  <Divider sx={{ width: "100%" }} />
                )}
              </React.Fragment>
            );
          })}
      </Grid>
    </React.Fragment>
  );
};

export default memo(DisplayNumberDataComponent);
