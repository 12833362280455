import { useMediaQuery } from "@mui/material";

export const useMediaAndOrientation =(query = '(max-width:900px)') =>{
    const isLandScape =  useMediaQuery('(orientation: landscape)');
    const heightCheck = useMediaQuery('(max-height:600px)');
    const widthCheck = useMediaQuery(query,{ noSsr: true });
    const isBigScreen = useMediaQuery('(min-width:900px)',{ noSsr: true });
    
    const isMobileScreen = !isBigScreen && (isLandScape?  heightCheck: widthCheck);

    return {isMobileScreen, isLandScape};
}
