import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { useDeepCompareDiffEffect } from "../../../hooks/useDeepCompareDiffEffect";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import {
  setCurrentFormat,
  setIsTextEditorActive,
} from "../../../store/selected-design-element-store";
import {
  getFonts,
  getFontSizes,
} from "../../../services/impact-report-service";
import { useDebounce, usePrevious } from "react-use";
import { Box } from "@mui/system";
import TextBubbleMenu from "./TextBubbleMenu";
import { Popper } from "@mui/material";
import { isEqual } from "lodash";
import { CustomClipboard } from "../../complexes/Editor/CustomClipboard";
import { CustomListItem } from "../../complexes/Editor/CustomListItem";

function TextEditor(props: any) {
  let Parchment = Quill.import("parchment");
  let availableSizes = Quill.import("attributors/style/size");
  // TODO: Move list generation to config
  availableSizes.whitelist = getFontSizes().map(
    (size: { value: string; label: number }) => size.value
  );
  Quill.register(availableSizes, true);

  let availableFonts = Quill.import("attributors/class/font");
  availableFonts.whitelist = getFonts().map(
    (font: { value: string; label: string }) => font.value
  );

  // For making the list bullets/numbers color same as that of that of the text
  const customColorAttributor = new Parchment.Attributor.Style(
    "custom-color",
    "color"
  );

  Quill.register(customColorAttributor, true);
  Quill.register(CustomListItem, true);
  Quill.register("modules/clipboard", CustomClipboard, true);
  Quill.register(availableFonts, true);

  const {
    id,
    isElementOnMove,
    attributes,
    updateElement,
    element,
    duplicateElement,
    deleteElement,
    isPublic,
    actionSource,
    height,
    width,
    scaleValue,
    selectedSelf,
    ops
  } = props;

  const _scaleValue = Number((+scaleValue).toFixed(4));;
  const prevDim = usePrevious({ height: height, width: width });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showPopper, setShowPopper] = useState(false);
  const [showSubMenu, setshowSubMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedFormatRef = useRef(null as any);
  const [scale, setScale] = useState(_scaleValue);
  let scaleRef = useRef(_scaleValue);
  let actionSourceRef = useRef("post-render");
  const [value, setValue] = useState(null as any);
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(value);
    },
    100,
    [value]
  );
  const [focused, setFocused] = useState(false);
  const reactQuillRef = useRef(null as any);
  const containerRef = useRef(null as any);
  var quillRef = useRef(null as any);
  var lastSelection = useRef(null as any);
  var firstLoad = useRef(true);
  const dispatch = useAppDispatch();
  const selectedElementCurrentFormat = useAppSelector(
    (state) => state.selectedDesignElement.format
  );

  useEffect(() => {
    if (focused && quillRef.current) {
      quillRef.current.focus();
    } else if (!focused && quillRef.current) {
      quillRef.current.blur();
    }
  }, [focused]);

  useEffect(() => {
    actionSourceRef.current = "post-render";
  }, [height, width, _scaleValue]);

  useEffect(() => {
    quillRef.current = reactQuillRef.current.getEditor();
    quillRef.current.setContents(attributes?.onLoadDelta?.ops);
    if (attributes?.html) {
      quillRef.current.clipboard.dangerouslyPasteHTML(attributes?.html);
      delete attributes?.html;
    }
  }, []);

  useEffect(() => {
    if (!selectedSelf) {
      setFocused(false);
      dispatch(setIsTextEditorActive(false));
      setAnchorEl(null);
      setShowPopper(false);
      setshowSubMenu(false);
    } else {
      setAnchorEl(containerRef.current);
      setShowPopper(true);
    }
  }, [selectedSelf]);

  const applyFormat = (
    format: any,
    type: string = "inline",
    persistLastSelection: boolean = false
  ) => {
    if (!focused) {
      quillRef.current.focus();
    }

    let { index, length } = quillRef.current.getSelection() || {
      index: 0,
      length: 0,
    };
    const text = quillRef.current.getText().trim();
    const [formatKey, formatValue] = Object.entries(format)[0];
    // If text editor is not in editmode, set length to full length
    if (!focused) {
      index = 0;
      length = quillRef.current.getLength();
    }
    if (text && type === "inline") {
      quillRef.current.formatText(
        index,
        length || formatKey,
        length ? format : formatValue
      );
    } else {
      const defaultLength = persistLastSelection
        ? length
        : quillRef.current.getLength();
      quillRef.current.formatText(
        persistLastSelection ? index : 0,
        defaultLength,
        format
      );
    }
    quillRef.current.setSelection(index, length, "api");
  };

  useEffect(() => {
    selectedFormatRef.current = selectedElementCurrentFormat;
    if (selectedElementCurrentFormat === null) {
      lastSelection.current = null;
    }
  }, [selectedElementCurrentFormat]);

  useEffect(() => {
    if (_scaleValue) {
      setScale(_scaleValue);
      scaleRef.current = _scaleValue;
      if (!firstLoad.current && ops === "fontSizeChange") {
        setTimeout(() => {
          if (containerRef?.current) {
            updateElement(
              {
                height:
                  containerRef?.current?.clientHeight * (scaleRef.current || 1),
                width: width,
              },
              "post-render"
            );
          }
        });
      }
    }
  }, [_scaleValue]);

  const updateWidthAndHeight = useCallback(() => {
    setTimeout(() => {
      if (containerRef?.current) {
        updateElement({
          height: containerRef.current.clientHeight * (scaleRef.current || 1),
        });
      }
    });
  }, [scale, width, updateElement]);

  const tolerancePercent = 0.02; // 2% tolerance
  const updateScaleAndDimensions = useCallback((newHeight: number, newWidth: number) => {
    const newScale = (Math.min(newHeight / prevDim?.height, newWidth / prevDim?.width)) * (scaleRef.current || 1);
    updateElement({ scale: newScale }, actionSourceRef.current);
    setScale(newScale);
    scaleRef.current = newScale;
  }, [updateElement, prevDim]);

  useEffect(() => {
    if (actionSource === "history") {
      return;
    }

    if ((!height && !width)) {
      setTimeout(() => {
        if (containerRef.current) {
          updateElement({
            height: containerRef.current.clientHeight * (scaleRef.current || 1),
            width: containerRef.current.clientWidth * (scaleRef.current || 1),
          });
        }
      });
    }
    const isDimensionChanged = (prev: number, current: number) => {
      const tolerance = prev * tolerancePercent;
      return Math.abs(prev - current) > tolerance;
    };


    const isBothDimensionsChanged = isDimensionChanged(prevDim?.width, width) && isDimensionChanged(prevDim?.height, height);
    const isWidthChanged = isDimensionChanged(prevDim?.width, width);
    if (isBothDimensionsChanged) {
      updateScaleAndDimensions(height, width);
    } else if (isWidthChanged) {
      updateWidthAndHeight();
    }
  }, [height, width]);

  

  useEffect(() => {
    if (!firstLoad.current && actionSourceRef.current !== "history") {
      let deltaData = quillRef?.current?.getContents();
      updateElement({ delta: deltaData }, actionSourceRef.current);
      setTimeout(() => {
        if (containerRef?.current) {
          updateElement({
            height: containerRef.current.clientHeight * (scaleRef.current || 1),
            width: containerRef.current.clientWidth * (scaleRef.current || 1),
          }, "post-render");
        }
      });
    }
  }, [debouncedValue]);

  const onChange = (content: any, delta: any, source: any, editor: any) => {
    let _text = editor.getText().trim();
    if (source === "user") {
      actionSourceRef.current = "user";
    }
    actionSourceRef.current = source === "user" ? "user" : "post-render";

    if (_text !== "" && !firstLoad.current) {
      // do nothing
    } else if (_text === "" && !firstLoad.current) {
      quillRef.current.format("color", attributes.color);
      quillRef.current.format("size", attributes.size + "px");
      quillRef.current.format("font", attributes.font);
      quillRef.current.format("italic", attributes.italic);
      quillRef.current.format("underline", attributes.underline);
      quillRef.current.format("align", attributes.align);
      quillRef.current.format("bold", attributes.bold);
      return;
    }
    setValue(content);
  };

  const onBlur = (range: any, source: string) => {
    if (source === "user") {
      setFocused(false);
      dispatch(setIsTextEditorActive(false));
    }
    setAnchorEl(null);
    setShowPopper(false);
    lastSelection.current = range;
  };

  const defaultFormat = {
    bold: false,
    list: '',
    align: '',
    italic: false,
    underline: false,
    blockquote: false,
  }

  const onChangeSelection = (range: any, source: any) => {
    if (source !== "silent") {
      let selection = quillRef?.current.getSelection();
      if (quillRef?.current && selection) {
        let _format = quillRef.current.getFormat();
        if (_format?.size) {
          _format = {
            ..._format,
            size: (+_format.size.replace("px", "") * scale).toFixed(1) + "px",
          };
        }
        dispatch(
          setCurrentFormat({
            format: {
              ...defaultFormat,
              ..._format,
            },
          })
        );
      }
    }
  };

  const makeEditable = (e: any) => {
    if (!isPublic && ((e.detail % 2 === 0 && !selectedSelf) || selectedSelf)) {
      setFocused(true);
      dispatch(setIsTextEditorActive(true));
      quillRef.current.setSelection(0, quillRef.current.getLength(), "api");
    }
  };

  const handleFocus = (e: any) => {
    if (!quillRef.current.hasFocus() && !isPublic) {
      quillRef.current.focus();
      setAnchorEl(e.currentTarget);
      setShowPopper(true);
    } else if (quillRef.current.hasFocus()) {
      setAnchorEl(null);
      setShowPopper(false);
    }
  };

  const updateText = (content: string) => {
    actionSourceRef.current = "user";
    let format = quillRef.current.getFormat();
    let deltaData = quillRef.current.setText(`${content}\n`);
    updateElement({ delta: deltaData }, actionSourceRef.current);
    let length = quillRef.current.getLength();
    deltaData = quillRef.current.formatText(0, length, format);
    updateElement({ delta: deltaData }, actionSourceRef.current);
    setTimeout(() => {
      updateElement({
        height: containerRef.current.clientHeight * (scaleRef.current || 1),
        width: containerRef.current.clientWidth * (scaleRef.current || 1),
      }, "post-render");
    });
  };
  const popperId = showPopper ? "simple-popper" : undefined;


  useDeepCompareDiffEffect(
    (diff = []) => {
      if (diff && Object.keys(diff).length > 0 && !firstLoad.current) {
        if (actionSource === "history") {
          quillRef.current.setContents(attributes?.onLoadDelta?.ops);
        }
    
        Object.keys(diff).forEach((key) => {
          let _value = "size" === key ? diff[key] + "px" : diff[key];
          let _type = ["align", "list", "blockquote"].includes(key)
            ? "block"
            : "inline";
          if (key === "onLoadDelta" || key === "text") return;
          if (quillRef?.current) {
            applyFormat({ [key]: _value }, _type, key === "color" ? true : false);
          }
        });
      }
    },
    [attributes]
  );

  useEffect(() => {
    firstLoad.current = false;
  }, []);

  return (
    <Box
      className="rtContainer"
      style={{
        transform: `scale(${scale})`,
        height: height && _scaleValue ? height / _scaleValue : `auto`,
        width: width && _scaleValue ? width / _scaleValue : `auto`,
        transformOrigin: "0px 0px",
      }}
    >
      <div
        ref={containerRef}>
        <div className="editable" onClick={handleFocus}>
          <ReactQuill
            style={{
              display: focused ? "block" : "none",
              fontSize: attributes.size,
            }}
            modules={{
              toolbar: false,
            }}
            readOnly={isPublic ? true : false}
            ref={reactQuillRef}
            theme="bubble"
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            onChangeSelection={onChangeSelection}
          />
        </div>
        {!isPublic && !isElementOnMove && (
          <Popper
            id={popperId}
            open={showPopper}
            anchorEl={anchorEl}
            style={{ zIndex: 1 }}
            placement="top-start"
          >
            <>
              <TextBubbleMenu
                id={id}
                updateText={updateText}
                setLoading={setLoading}
                quillRef={quillRef}
                loading={loading}
                element={element}
                showSubMenu={showSubMenu}
                handleWandClick={() => setshowSubMenu(!showSubMenu)}
                duplicateElement={duplicateElement}
                deleteElement={deleteElement}
              ></TextBubbleMenu>
            </>
          </Popper>
        )}
        <div
          className={`ql-container ${isPublic ? "public" : ""} ql-bubble`}
          onClick={makeEditable}
        >
          <div
            className="not-editable ql-editor"
            style={{
              display: focused ? "none" : "block",
              fontSize: attributes.size,
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: value }} />
          </div>
        </div>
      </div>
    </Box>
  );
}

function arePropsEqual(prevProps: any, nextProps: any) {
  return isEqual(JSON.stringify(prevProps), JSON.stringify(nextProps));
}

export default React.memo(TextEditor, arePropsEqual);
