import {
  Box,
  Button,
  Divider,
  Grid,
  Input,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { isEqual } from "lodash";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual } from "react-redux";
import AddPageIcon from "../../../assets/add_page.png";
import PageColorIcon from "../../../assets/color_icon.png";
import CopyElementIcon from "../../../assets/copy_element.png";
import SetBackgroundIcon from "../../../assets/set_background.png";
import CopyPageIcon from "../../../assets/copy_page.png";
import DeleteIcon from "../../../assets/deleteIcon.png";
import PageDeleteIcon from "../../../assets/delete_red.png";
import MoveIcon from "../../../assets/move_icon.png";
import MovedownIcon from "../../../assets/movedown.png";
import MoveupIcon from "../../../assets/moveup.png";
import { gradientPalette, theme } from "../../../theme";
import LeftIcon from "../../../assets/left_arrow.png";
import RightIcon from "../../../assets/right_arrow.png";

import {
  getClipStylesFromBounds,
  getElementBounds,
} from "../../../utils/ui-utils";
import Element from "../Editor/Element";
import "./Design.scss";
import { ThemeColorPicker } from "./ThemeColorPicker";
import { useAppDispatch } from "../../../store/hook";
import {
  setSettingEditActive,
  setIsTextEditorActive,
} from "../../../store/selected-design-element-store";
import {
  addAlphaToHexCode,
  checkVaildHexCode,
  gradientColorStyleCombined,
} from "../../../utils/color-picker-helper";
import { useMediaAndOrientation } from "../../../hooks/useMediaAndOrientation";

function Page(props: any) {
  const pageRef = useRef<HTMLDivElement>(null);
  const {
    width,
    height,
    fill,
    id,
    index,
    pageColor,
    pageBackground,
    pageTitle,
    pageBackgroundFilter,
    position,
    setPageBounds,
    _destroy,
    addPage,
    updatePage,
    pageAttributes,
    deletePage,
    copyPage,
    page,
    onMove,
    isPublic,
    pagesLength,
    clipBoardItem,
    pasteElement,
    addElement,
    moveRef,
    selectedElement,
    zoom,
    selectCallback,
    toggleCustomAbles,
    isElementOnMove,
    lastEle,
    zoomRef,
    lastRef,
    updateElementById,
    deleteElementById,
    updateZIndex,
    copyItem,
    duplicateElement,
    editorSource,
    reportUID,
    isTemplateEdit,
    isPresentation,
    currentPageIndex,
    setCurrentPageIndex,
    keepRatio,
    groupTargets,
    repeatOnAllPages
  } = props;

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [displayPicker, setDisplayPicker] = useState(false);
  const [pageName, setPageName] = useState(pageTitle);
  const [color, setColor] = useState<any>(pageColor);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const dispatch = useAppDispatch();
  const font = theme.typography.inter_p_600_16.fontFamily;

  useEffect(() => {
    if (pageRef.current && !_destroy) {
      setPageBounds(id, getElementBounds(pageRef.current));
    }
  }, [id, index, zoom]);
  
  // For element loading dynamically, like image and KPIs
  const onElementLoad = useCallback(() => {
    moveRef.current?.updateRect();
  }, []);

  const onDrop = (e: any) => {
    e.preventDefault();
  };

  const allowDrop = (ev: any) => {
    ev.preventDefault();
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : null
    );
    event.stopPropagation();
  };
  const handleClose = () => {
    setContextMenu(null);
  };

  const _onMove = (id: string, change: number) => {
    onMove(id, change);
    handleClose();
  };

  const _pasteElement = (e: any) => {
    pasteElement({ x: e.pageX, y: e.pageY });
    handleClose();
  };

  const _deletePage = (id: string) => {
    deletePage(id);
    handleClose();
  };

  const updateHexCode = (hexCode: string) => {
    updatePage(id, {
      attributes: {
        ...pageAttributes,
        color: hexCode,
        backgroundImage: "",
      },
    });
    setColor(hexCode);
  };

  const togglePicker = (e: any) => {
    if (!displayPicker) {
      setAnchorEl(e.currentTarget);
    }
    dispatch(setSettingEditActive(!displayPicker));
    setDisplayPicker(!displayPicker);
  };

  const handlePickerClose = () => {
    setAnchorEl(null);
    dispatch(setSettingEditActive(false));
    setDisplayPicker(false);
  };

  const updatePageColor = (color: any, gradient: any) => {
    if (color.hex === "transparent") {
      color = "#fff";
    } else {
      color = !checkVaildHexCode(color)
        ? addAlphaToHexCode(color.hex, color.rgb["a"])
        : color;
    }
    if (gradient.type !== "solid") {
      if (gradient.selectedColor && checkVaildHexCode(gradient.selectedColor)) {
        let index = gradientPalette.indexOf(gradient.selectedColor);
        if (~index) {
          gradientPalette[index] = color;
        }
      } else if (gradient.action === "add_color") {
        gradientPalette.push(color);
      } else if (gradient.action === "remove_color") {
        gradientPalette.pop();
      }
      let gradientColorStyle = gradientColorStyleCombined(
        gradientPalette,
        gradient.style
      );
      updatePage(id, {
        attributes: {
          ...pageAttributes,
          backgroundImage: gradientColorStyle,
        },
      });
      setColor(color);
    } else {
      updatePage(id, {
        attributes: {
          ...pageAttributes,
          color: color,
          backgroundImage: "",
        },
      });
      setColor(color);
    }
  };

  const _detachBackground = () => {
    updatePage(id, {
      attributes: {
        ...pageAttributes,
        backgroundImage: "",
        color: "",
      },
    });
  };

  const disableActive = () => {
    dispatch(setIsTextEditorActive(false));
  };

  const onSelect = (e: any, element: any) => {
    if (isPublic) return;
    selectCallback(e, element);
    e.stopPropagation();
  };

  const renderElements = () => {
    if (!page?.elements) return;
    return Object.keys(page.elements).map((id: any, i: any, { length }) => {
      let element = page.elements[id];
      if (element?._destroy) return null;
      let _clipStyles = getClipStylesFromBounds(
        element.clipStyleBounds,
        1, //ZOOMTODO
        "style"
      );
      return (
        <div
          className={`elementContainer element_${page.id}_${element.id}`}
          data-pageid={element.pageId}
          data-elementid={element.id}
          data-elementtype={element.type}
          key={i}
          style={{
            position: "absolute",
            zIndex: element.zIndex,
            height: element?.height || "auto",
            width: element?.width || "auto",
            padding: element?.padding || 0,
            opacity: element?.opacity * 0.01 || 1,
            transform: `translate(${element.posX}px, ${element.posY
              }px) rotate(${element?.rotate || 0}deg)`,
            clipPath: typeof _clipStyles === "string" ? _clipStyles : "inset",
            display: "block",
            outline:
              element.id !== selectedElement?.id &&
                isElementOnMove &&
                selectedElement?.pageId == page.id
                ? `1px dashed ${theme.impactReportingColors.guidelineColor}`
                : "",
          }}
          onClick={(e) => onSelect(e, element)}
          ref={(_element) => {
            if (lastEle?.current?.id === element.id && _element && !groupTargets) {
              lastRef.current = _element;
            }
          }}
        >
          <Element
            isPresentation={isPresentation}
            reportUID={reportUID}
            isTemplateEdit={isTemplateEdit}
            key={element.pageId + "_" + element.id}
            element={element}
            isElementSelected={
              selectedElement &&
              selectedElement.id === element.id &&
              selectedElement.pageId === element.pageId
            }
            isPublic={isPublic}
            onLoad={onElementLoad}
            isElementOnMove={isElementOnMove}
            pageHeight={page.height}
            pageWidth={page.width}
            toggleCustomAbles={toggleCustomAbles}
            pageId={page.id}
            updateElement={updateElementById}
            deleteElement={deleteElementById}
            addElement={addElement}
            updateZIndex={updateZIndex}
            actionSource={editorSource}
            keepRatio={keepRatio}
            duplicateElement={duplicateElement}
            copyElement={copyItem}
            pasteElement={pasteElement}
            updatePage={updatePage}
            source={"impact-reporting"}
            zoom={zoom}
            selectedElementID={selectedElement?.id}
            repeatOnAllPages={repeatOnAllPages}
            pagesLength={pagesLength}
            pageIndex={index}
          />
          {selectedElement &&
            selectedElement.id === element.id &&
            selectedElement.pageId === element.pageId
            && !groupTargets ? (
            <Box
              style={{
                position: "absolute",
                left: "calc(50% - 11px)",
                bottom: `calc(-33px * (1/${zoomRef.current}))`,
                transform: `scale(calc(1/${zoomRef.current}))`,
              }}
            >
              <img src={MoveIcon} height={22} alt={"move item"} />
            </Box>
          ) : (
            <></>
          )}
        </div>
      );
    });
  };
  const {isMobileScreen, isLandScape} =  useMediaAndOrientation();

  return (
    <div style={{ position: isPresentation ? "relative" : "inherit" }}>
      <Grid
        item
        className="page_action top"
        mt={index === 1 ? 0 : 8}
        style={{
          display: isPresentation ? "none" : "flex",
        }}
      >
        <Stack
          spacing={2}
          direction="row"
          justifyContent={"space-between"}
          style={{
            display: isPublic ? "none" : "flex",
            width: "100%",
          }}
        >
          <div className={"page-number"}>
            <Button
              onClick={togglePicker}
              sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
            >
              <img src={PageColorIcon} />
            </Button>
            {displayPicker ? (
              <ThemeColorPicker
                id={`page-${index}`}
                anchorEl={anchorEl}
                handleClose={handlePickerClose}
                closePicker={togglePicker}
                gradientTab={true}
                updateHexCode={updateHexCode}
                color={color}
                onChange={updatePageColor}
                gradientApplied={pageBackground?.startsWith('url') ? '' : pageBackground}
              />
            ) : null}
            <Typography variant="inter_p_600_16">
              Page {index} -{" "}
              <TextField
                id="Pagename"
                placeholder="Add page title"
                onChange={(e) => {
                  updatePage(id, {
                    attributes: {
                      ...pageAttributes,
                      pageTitle: e.target.value,
                    },
                  });
                  setPageName(e.target.value);
                }}
                value={pageName}
                variant="standard"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: font,
                    padding: 0,
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px dashed gray",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "1px dashed gray",
                  },
                }}
              />
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Stack direction={"row"} spacing={1}>
              <div>
                <Button
                  variant="text"
                  style={{ minWidth: "34px" }}
                  onClick={() => copyPage(id)}
                >
                  <Tooltip title="Duplicate">
                    <img
                      src={CopyPageIcon}
                      alt="Duplicate page"
                      style={{ maxHeight: "18px" }}
                    />
                  </Tooltip>
                </Button>
              </div>
              <div>
                <Button
                  variant="text"
                  style={{ minWidth: "30px" }}
                  onClick={() => addPage(position + 1)}
                >
                  <Tooltip title="Add">
                    <img
                      src={AddPageIcon}
                      alt="Add page"
                      style={{ maxHeight: "18px" }}
                    />
                  </Tooltip>
                </Button>
              </div>
              <div>
                <Button
                  variant="text"
                  style={{ minWidth: "30px" }}
                  onClick={() => deletePage(id)}
                  disabled={pagesLength <= 1}
                >
                  <Tooltip title="Delete">
                    <img
                      src={DeleteIcon}
                      alt="Delete page"
                      style={{ maxHeight: "18px" }}
                    />
                  </Tooltip>
                </Button>
              </div>
              <div>
                <Button
                  variant="text"
                  style={{ minWidth: "30px" }}
                  onClick={() => onMove(id, -1)}
                  disabled={index === 1 || pagesLength <= 1}
                >
                  <Tooltip title="Move Up">
                    <img
                      src={MoveupIcon}
                      alt="Add page"
                      style={{ maxHeight: "18px" }}
                    />
                  </Tooltip>
                </Button>
              </div>
              <div>
                <Button
                  variant="text"
                  style={{ minWidth: "30px" }}
                  onClick={() => onMove(id, 1)}
                  disabled={index === pagesLength || pagesLength <= 1}
                >
                  <Tooltip title="Move Down">
                    <img
                      src={MovedownIcon}
                      alt="Add page"
                      style={{ maxHeight: "18px" }}
                    />
                  </Tooltip>
                </Button>
              </div>
            </Stack>
          </div>
        </Stack>
        <Stack
          spacing={2}
          direction="row"
          justifyContent={"space-between"}
          style={{
            display: isPublic ? (isPresentation ? "none" : "flex") : "none",
          }}
        >
          <div className={"page-number"}>
            <Typography
              variant="inter_p_600_16"
              sx={{ color: theme.custom.borderColor }}
            >
              Page {index} -{` ` + pageTitle}
            </Typography>
          </div>
        </Stack>
      </Grid>
      <Grid
        item
        className={`page_${page.id}`}
        id={`page_${page.id}`}
        onContextMenu={handleContextMenu}
        style={{
          height: height * zoom,
          width: width * zoom,
          marginTop: isPresentation && !isMobileScreen? "24px" : "0px",
        }}
      >
        <div
          style={{
            height: height * zoom,
            width: width * zoom,
            ...(isMobileScreen && isPresentation) && {transform: `translateY(calc(50vh - ${height *zoom/2}px - 80px))`},
          }}
        >
          <Box
            ref={pageRef}
            className="element no-cursor page snap"
            style={{
              height: height,
              width: width,
              overflow: "hidden",
              backgroundColor: pageColor || fill,
              position: "relative",
              boxShadow: "rgb(14 19 24 / 20%) 0px 2px 14px 3px",
              transform: `scale(${zoom})`,
              transformOrigin: "0 0",
            }}
            sx={{
              "&::before": {
                content: '""',
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
                backgroundSize: "cover",
                filter: pageBackgroundFilter,
                backgroundImage: pageBackground,
              },
            }}
            onDragOver={(e) => allowDrop(e)}
            onDrop={onDrop}
          >
            <div style={{ position: "relative" }}>{renderElements()}</div>
          </Box>
        </div>

        <Menu
          style={{
            zIndex: 3001,
            display: isPublic ? "none" : "block",
          }}
          sx={{
            "& .MuiMenu-paper": {
              padding: "18px 12px 18px 12px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.12)",
              borderRadius: "6px",
            },
            "& .MuiMenuItem-root": {
              margin: "6px",
            },
          }}
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          {pageBackground && (
            <MenuItem onClick={_detachBackground}>
              <ListItemIcon>
                <img
                  src={SetBackgroundIcon}
                  alt="Detach Background"
                  style={{ maxHeight: "18px" }}
                />
              </ListItemIcon>
              <ListItemText>Remove Background</ListItemText>
            </MenuItem>
          )}
          <MenuItem onClick={_pasteElement} disabled={!clipBoardItem.value}>
            <ListItemIcon>
              <img
                src={CopyElementIcon}
                alt="Paste Element"
                style={{ maxHeight: "18px" }}
              />
            </ListItemIcon>
            <ListItemText>Paste</ListItemText>
            <Typography>
              <kbd className={"short-command"}>⌘V</kbd>
            </Typography>
          </MenuItem>
          <Divider sx={{ borderColor: theme.custom.menuDividerColor }} />
          <MenuItem
            onClick={() => _onMove(id, -1)}
            disabled={pagesLength <= 1 || index === 1}
          >
            <ListItemIcon>
              <img
                src={MoveupIcon}
                alt="Delete Element"
                style={{ maxHeight: "18px" }}
              />
            </ListItemIcon>
            <ListItemText>Move page up</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => _onMove(id, 1)}
            disabled={pagesLength <= 1 || index === pagesLength}
          >
            <ListItemIcon>
              <img
                src={MovedownIcon}
                alt="Send Backwards"
                style={{ maxHeight: "18px" }}
              />
            </ListItemIcon>
            <ListItemText>Move page down</ListItemText>
          </MenuItem>
          {pagesLength > 1 && (
            <Divider sx={{ borderColor: theme.custom.menuDividerColor }} />
          )}
          {pagesLength > 1 && (
            <MenuItem onClick={() => _deletePage(id)}>
              <ListItemIcon>
                <img
                  src={PageDeleteIcon}
                  alt="Delete"
                  style={{ maxHeight: "18px" }}
                />
              </ListItemIcon>
              <ListItemText>Delete page</ListItemText>
              <Typography>
                <kbd className={"short-command"}>del</kbd>
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </Grid>

      {isPresentation ? (
        <>
          <img
            src={LeftIcon}
            width={40}
            height={40}
            style={{              
              ...!(isMobileScreen && !isLandScape) ? 
              {position: "absolute",
                top: `calc(50% - ${currentPageIndex == 0 && !isMobileScreen? "20px" : "0px"})`,
                left: "-60px",}:
              {position: "fixed",
              top : "60px",
              left: "50%",
              transform: "translateX(-50%) rotate(90deg)",  
              zIndex: "50000",},
              cursor: "pointer",
              display: isPresentation ? "block" : "none",
            }}
            onClick={() =>
              setCurrentPageIndex(Math.max(currentPageIndex - 1, 0))
            }
          />

          <img
            src={RightIcon}
            width={40}
            height={40}
            style={{
              ...!(isMobileScreen && !isLandScape) ? 
              {position: "absolute",
              top: `calc(50% - ${currentPageIndex == 0 && !isMobileScreen? "20px" : "0px"})`,
              right: "-60px",}: 
              {position: "fixed",
              bottom : "50px",
              left: "50%",
              transform: "translateX(-50%) rotate(90deg)",  
              zIndex: "50000",},
              cursor: "pointer",
              display: isPresentation ? "block" : "none",
            }}
            onClick={() =>
              setCurrentPageIndex(
                Math.min(currentPageIndex + 1, pagesLength - 1)
              )
            }
          />
        </>
      ) : (
        ""
      )}
      <Grid
        item
        className="page_action bottom"
        onClick={() => addPage(position + 1)}
        style={{
          top: "20px",
          cursor: "pointer",
          display: isPublic ? "none" : "block",
          marginTop: "10px",
        }}
      >
        <Typography
          variant="poppins_p_600_14"
          sx={{ color: theme.custom.primaryGreyColor }}
        >
          + Add Page
        </Typography>
      </Grid>
    </div>
  );
}

function propsAreEqual(prevProps: any, nextProps: any) {
  // return true to supress rerender.
  const {
    selectedElement: prevSelectedElement,
    id: prevId,
    isElementOnMove: prevIsElementOnMove,
    page: prevPage,
    ...prevRest
  } = prevProps;
  const {
    selectedElement: nextSelectedElement,
    id: nextId,
    isElementOnMove: nextIsElementOnMove,
    page: nextPage,
    ...nextRest
  } = nextProps;

  let selectedElementModifyingPages: boolean = false;
  if (prevSelectedElement && nextSelectedElement) {
    selectedElementModifyingPages =
      prevSelectedElement.pageId === prevId ||
      prevSelectedElement.pageId === nextId ||
      nextSelectedElement.pageId === prevId ||
      nextSelectedElement.pageId === nextId;
  }
  // deep check for equality by value.
  const pageEqual = isEqual(prevPage, nextPage);

  // use same logic provided by react for shallow comparison
  const shallow_comparison_equal = shallowEqual(prevRest, nextRest);
  const result =
    (selectedElementModifyingPages
      ? shallowEqual(
        [prevSelectedElement, prevIsElementOnMove],
        [nextSelectedElement, nextIsElementOnMove]
      )
      : true) &&
    prevId === nextId &&
    pageEqual &&
    shallow_comparison_equal;
  return result;
}

export default memo(Page, propsAreEqual);
