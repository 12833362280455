import { List, ListItem, Stack, Tooltip, Typography, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { getKPILibrary } from "../../../services/impact-report-service";
import InfiniteScroll from "react-infinite-scroll-component";
import { theme } from "../../../theme";
import AuthorizationErrorHandler from "./AuthorizationErrorHandler";
import { TagObject } from "../../../Types/TagObject";
import TagComponent from "../TagComponent";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { filter, find, set } from "lodash";

function TabularReportLibrary(props: any) {
  let cursorPosition = useRef({ left: 0, top: 0 });
  let pageRef = useRef({ page: 1 });
  const [tabularEditorLib, setTabularEditorLib] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreKPI, sethasMoreKPI] = useState(false);
  const [authorized, setAuthorized] = useState(true);
  const [kpiTags, setKPITags] = useState([] as TagObject[]);
  const [tagLoading, setTagLoading] = useState(false);

  useEffect(() => {
    fetchKPIs(1);
  }, []);

  const onTagSelected = (selected: boolean, slug: string) => {
    let _kpiTags: TagObject[] = [...kpiTags];
    setTagLoading(true);
    if (_kpiTags && _kpiTags.length > 0) {
      set(find(_kpiTags, ["slug", slug]) || {}, ["selected"], selected);
      setKPITags(_kpiTags);
      let _selectedTags = filter(_kpiTags, ["selected", true]).map(
        (ele) => ele.slug
      );
      if (tagLoading) {
        fetchKPIs(1, _selectedTags);
      }
    }
  };

  const fetchKPIs = (page: number, tags?: string[]) => {
    getKPILibrary("tabular_kpi", page, "", tags, props.reportUID).then((resp) => {
      if (resp.status === 403) {
        setAuthorized(false);
        return;
      }
      if (resp.status === 200) {
        if (resp.data) {
          if (!tags) {
            if (resp.data && resp.data.tags && resp.data.tags.length > 0) {
              setKPITags(
                resp.data.tags.map((ele: TagObject) => {
                  return { ...ele, selected: false };
                })
              );
            }
          }
          if (page === 1) {
            setTabularEditorLib([...resp.data.results]);
          } else {
            setTabularEditorLib([...tabularEditorLib, ...resp.data.results]);
          }
          if (resp.data.next) {
            pageRef.current.page = page + 1;
            sethasMoreKPI(true);
            setIsLoading(false);
            return;
          }
          sethasMoreKPI(false);
          setIsLoading(false);
        } else {
          sethasMoreKPI(false);
        }
      } else {
        sethasMoreKPI(false);
      }
    });
  };
  const addElem = (e: any, element: any, dnd: boolean = false) => {
    const { addElement } = props;
    const _element = {
      type: "TabularReportEditor",
      transparency: 1,
      zIndex: 0,
      posX: 100,
      posY: 100,
      height: element.attributes.height / 0.4,
      width: element.attributes.width / 0.4,
      rotate: 0,
      firstTimeEleAddition:true,
      attributes: { ...element.attributes, commKey: element.communication_key },
    };

    addElement(
      _element,
      {
        left: cursorPosition.current.left,
        top: cursorPosition.current.top,
      },
      "drop",
      "",
      dnd
    );
    e.preventDefault();
  };
  const onDrag = (e: any) => {
    //TODO: On DragEnd page coordinates are coming wrong.
    // Monkey patch to force correct it. Heavy ops issue here.
    cursorPosition.current.top = e.pageY;
    cursorPosition.current.left = e.pageX;
  };

  const onDragStart = (e: any) => {
    // Do nothing
  };

  const loadMore = () => {
    setTagLoading(false);
    fetchKPIs(pageRef.current.page);
  };
  return (
    <>
      <Box sx={{
        position: "relative"
      }} pt={2}>
        <Box sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          backgroundColor: 'white',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        }}
          mx={-2} mt={-2} px={1} pt={2} pb={1}>
          <Box my={2} className="kpi-styles-wrapper">
            <Swiper
              navigation={true}
              modules={[Navigation]}
              slidesPerView={3}
              spaceBetween={10}
              className="mySwiper"
            >
              {kpiTags &&
                kpiTags.map((ele, index) => {
                  return (
                    <SwiperSlide>
                      <TagComponent
                        isSelected={ele.selected}
                        tagName={ele.name}
                        slug={ele.slug}
                        onSelect={onTagSelected}
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Box>
        </Box>
        {authorized && (
          <Box mt={3}>
            <InfiniteScroll
              dataLength={tabularEditorLib.length}
              next={loadMore}
              hasMore={hasMoreKPI}
              loader={
                tabularEditorLib.length < 20 ? (
                  ""
                ) : (
                  <p style={{ textAlign: "center" }}>
                    <Typography
                      sx={{ color: theme.custom.primaryGreyColor }}
                      variant="inter_p_600_16"
                    >
                      Loading...
                    </Typography>
                  </p>
                )
              }
              scrollableTarget="scrollableDiv"
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <Typography
                    sx={{ color: theme.custom.primaryGreyColor }}
                    variant="inter_p_600_16"
                  >
                    No more reports to load.
                  </Typography>
                </p>
              }
            >
              <List>
                {tabularEditorLib.map((ele: any) => {
                  return (
                    <Box>
                      <Tooltip
                        key={ele.id}
                        title={ele.attributes.title || "Unnamed"}
                        placement="top"
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -20],
                              },
                            },
                          ],
                        }}
                      >
                        <ListItem
                          draggable={true}
                          onDrag={onDrag}
                          onDragStart={onDragStart}
                          onDragEnd={(e) => addElem(e, ele)}
                          onClick={(e) => addElem(e, ele, false)}
                          style={{
                            cursor: "pointer",
                            border: `1px solid ${theme.custom.borderColor}`,
                            margin: 4,
                            borderRadius: "6px",
                            maxWidth: 250,
                          }}
                        >
                          <Stack alignItems={"center"} direction="row">
                            <div
                              style={{
                                backgroundImage: `url(${ele.attributes.thumbnail_url})`,
                                marginRight: 10,
                                height: 30,
                                width: 30,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                              }}
                            />
                            <Typography
                              sx={{
                                maxWidth: 150,
                                color: theme.custom.primaryDarkColor,
                              }}
                              noWrap
                              variant="inter_400_14"
                            >
                              {ele?.attributes?.title}
                            </Typography>
                          </Stack>
                        </ListItem>
                      </Tooltip>
                    </Box>
                  );
                })}
              </List>
            </InfiniteScroll>
          </Box>
        )}

      </Box>
      {!authorized && (
        <AuthorizationErrorHandler
          errorMessage="You don’t have access to see the list of KPI's. Please reach out to
        admin to get access"
        />
      )}
    </>
  );
}

export default React.memo(TabularReportLibrary);
