import React, { memo, useEffect } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  axisLabelStyle,
  chartLabelsStyle,
  fontInter,
  returnChartColors,
  theme,
  tooltipColors,
} from "../../theme";
import { numberFormatter, labelNumberFormatter } from "../../utils/dataFormatters";
import { returnFileName } from "../../utils/file-name-helper";
import { ChartProps } from "../../Types/ChartProps";
import {
  returnGroupedColumnOrBarChartData,
  returnToolTipForGroupedBarsOrColumns,
} from "../../utils/CommonKPIResponseFormatterHelper";

const GroupedBarChart: React.FC<ChartProps> = (chartData) => {
  let data = chartData.chartData;
  let meta_data = chartData.chartMeta;
  let data_type = meta_data?.chart?.currencytype || "$";
  let date_range = chartData.dateRange;
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const height = chartData.height;
  const width = chartData.width;
  const editorMode = chartData.editorMode;

  let showTable = chartData.showTable;
  let disableDataLabels = meta_data.chart["data-label-disabled"] || false;

  useEffect(() => {
    if (chartComponentRef.current) {
      if (showTable) {
        chartComponentRef.current?.chart.viewData();
      } else {
        chartComponentRef.current.chart.hideData();
      }
    }
  }, [showTable]);

  const options = {
    chart: {
      type: "bar",
      zoomType: editorMode ? "" : "xy",
      events: {
        render: function () {
          chartData.kpiRendered(true);
          chartData.chartReference(chartComponentRef);
          if (chartData.customKPI === false) {
            chartComponentRef.current?.chart.reflow();
          }
        },
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      title: {
        text: meta_data.chart?.["x-axis-text"] || "",
        style: axisLabelStyle,
        margin: 20,
      },
      categories: returnToolTipForGroupedBarsOrColumns(data),
      labels: {
        formatter: function (this: any) {
          return `<span style="${chartLabelsStyle}">${this.value}</span>`;
        },
      },
    },
    yAxis: {
      title: {
        text: meta_data.chart?.["y-axis-text"] || "",
        style: axisLabelStyle,
      },
      labels: {
        overflow: "justify",
        formatter: function (this: any) {
          return `<span style="${chartLabelsStyle}">${labelNumberFormatter(
            this.value,
            data_type,
            true
          )}</span>`;
        },
      },
    },
    tooltip: {
      backgroundColor: tooltipColors.bgColor,
      borderColor: tooltipColors.borderColor,
      color: tooltipColors.dataFormatColor,
      padding: 10,
      borderRadius: 5,
      borderWidth: 1,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return `<span style="color: ${theme.custom.whiteColor}"> &nbsp;${
          this.key
        } - </span>  
        <span style="color: ${theme.custom.whiteColor}">${
          this.series.name
        }: ${labelNumberFormatter(this.y, data_type)}</span><br/>
        
        `;
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          inside: true,
          enabled: !disableDataLabels,
          formatter: function (this: any) {
            return numberFormatter(this.y, data_type, true);
          },
          style: {
            fontWeight: 500,
            fontFamily: fontInter,
            color: theme.custom.primaryDarkColor,
          },
        },
        shadow: false,
        borderWidth: 0,
        borderRadiusTopLeft: "10%",
        borderRadiusTopRight: "10%",
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    legend: {
      enabled: meta_data?.legend?.enabled || false,
    },
    colors: returnChartColors(data && data.length),
    credits: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    exporting: {
      filename: returnFileName(meta_data?.chart?.title || "", date_range),
    },
    series: returnGroupedColumnOrBarChartData(data, meta_data),
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{
        className: "chart",
        style: {
          width: "100%",
          height: "calc(100% - 1px)",
        },
      }}
      options={
        editorMode
          ? {
              ...options,
              ...{ chart: { ...options.chart, height: height, width: width } },
            }
          : options
      }
      ref={chartComponentRef}
    ></HighchartsReact>
  );
};

export default memo(GroupedBarChart);
