import { Grid } from "@mui/material";
import { useRef } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

interface Props {
  thumbnail: any;
  blurBackground: boolean;
  isPresentation:boolean;
}

const ThumbnailSlider = (props: Props) => {
  let thumbnail = props.thumbnail;

  const swiperRef = useRef<any>();
  const onInit = (Swiper: any): void => {
    swiperRef.current = Swiper;
    swiperRef.current?.autoplay.stop();
  };
  const handleMouseEnter = () => {
    swiperRef.current?.autoplay.start();
  };
  const handleMouseLeave = () => {
    swiperRef.current?.autoplay.stop();
  };

  return (
    <Grid
      container
      className={props.blurBackground ? "report-thumbnail-image" : ""}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        zIndex: 0,
        maxWidth: "100%",
        maxHeight: "100%",
        minHeight: 0,
        minWidth: 0,
        textAlign: "center",
      }}
      justifySelf={"center"}
    >
      <Swiper
        onInit={onInit}
        speed={1000}
        slidesPerView={1}
        spaceBetween={24}
        loop
        updateOnWindowResize
        modules={[Autoplay]}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
      >
        {thumbnail &&
          thumbnail.length > 0 &&
          thumbnail.map((item: any) => (
            <SwiperSlide>
              <img
                src={item}
                style={{
                  borderRadius: props.isPresentation? "0px": "12px",
                  width: "100%",
                  height: "auto",
                  // maxHeight: "240px",
                }}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </Grid>
  );
};

export default ThumbnailSlider;
