import React, { HTMLProps, memo, useEffect, useRef, useState } from "react";
import { MetaDataObject } from "../../Types/MetaDataObject";
import "./DataTableMolecule.scss";
import { each, isEmpty, map, omit, orderBy } from "lodash";

import DataTable from "../atoms/DataTable";
import CustomTableCell from "../atoms/data-table/CustomTableCell";
import CustomRichTextCell from "../atoms/data-table/CustomRichTextCell";
import CustomRangeCell from "../atoms/data-table/CustomRangeCell";
import CustomHyperLinkCell from "../atoms/data-table/CustomHyperLinkCell";
import CustomPopover from "../atoms/data-table/CustomPopover";
import { Tooltip } from "@mui/material";
import CustomMonthCalanderCell from "../atoms/data-table/CustomMonthCalanderCell";
import CustomAttachmentsCell from "../atoms/data-table/CustomAttachmentsCell";

type Props = {
  isFullscreen?: boolean;
  setFullscreen?: Function;
  chartData: any;
  chartMeta: MetaDataObject;
  kpiRendered?: React.Dispatch<React.SetStateAction<boolean>>;
  forReport?: boolean;
  drillDownConfig?: Object;
  onDataPointClick?: Function;
  slug?: string;
  pagination?: Object;
  onPaginationChange?: Function;
  onUpdateCallback?: Function;
  onRowsSelection?: Function;
  onSingleRowSelection?: Function;
  onAttachmentClick?: Function;
  handleIgnoreList?: Function;
  extraProps?: any;
  selectedRows?: any;
  onRender?: Function;
  flushIgnoredAttachments?: Function;
  ignoredAttachments?: Object;
};

const DataTableMolecule: React.FC<Props> = (chartData) => {
  const tableObjRef = useRef<any>(null);

  const getTableReference = (tableRef: any) => {
    tableObjRef.current = tableRef;
  };
  const selectAllRows = (tableObj: any) => {
    if (tableObj) {
      if(!tableObj.getIsAllRowsSelected() && chartData?.flushIgnoredAttachments) {
        chartData.flushIgnoredAttachments();
      }
      tableObj.toggleAllRowsSelected();
      each(tableObj.getSortedRowModel().rowRef, (ele: any) => {
        ele.toggleSelected(true);
      });

      if (onRowsSelection) {
        onRowsSelection(
          !tableObj.getIsAllRowsSelected() === false
            ? []
            : tableObj.getSortedRowModel().rows,
          !tableObj.getIsAllRowsSelected() || false
        );
      }
    }
  };

  const selectSingleRow = (rowRef: any) => {
    if (rowRef) {
      rowRef.toggleSelected(!rowRef.getIsSelected());
      if (onSingleRowSelection) {
        onSingleRowSelection(!rowRef.getIsSelected(), rowRef);
      }
    }
  };

  function IndeterminateCheckbox({
    indeterminate,
    className = "",
    type = "",
    tableRef = null,
    rowRef = null,
    ...rest
  }: { indeterminate?: boolean } & any) {
    const ref = useRef<HTMLInputElement>(null!);

    useEffect(() => {
      if (typeof indeterminate === "boolean") {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);

    const onChange = (type: string) => {
      if (type === "select-all") {
        selectAllRows(tableRef);
      } else if (type === "select-single") {
        selectSingleRow(rowRef);
      }
    };
    return (
      <input
        type="checkbox"
        ref={ref}
        className={className + " cursor-pointer"}
        onChange={() => onChange(type)}
        {...rest}
      />
    );
  }

  let kpiData = chartData.chartData;

  let slug = chartData.slug;
  let meta_data = chartData.chartMeta;
  let columnsToBeHidden = [
    ...(meta_data["chart"]?.["hidden_columns"] || []),
    ...[
      "nesting_order",
      "type",
      "order",
      "subRows",
      "transaction_external_link",
    ],
  ];
  let enableRowSelection = meta_data.chart.enable_row_selection;
  let onDataPointClick = chartData.onDataPointClick;
  let onUpdateCallback = chartData.onUpdateCallback;
  let onRowsSelection = chartData.onRowsSelection;
  let onSingleRowSelection = chartData.onSingleRowSelection;
  let onAttachmentClick = chartData.onAttachmentClick;
  let handleIgnoreList = chartData.handleIgnoreList;
  const [selectedRows, setSelectedRows] = useState({} as any);

  useEffect(() => {
    setSelectedRows(chartData.selectedRows);
  }, [chartData.selectedRows]);

  useEffect(() => {
    if (chartData.chartData && chartData.chartData.length && selectedRows) {
      if (selectedRows.selectAll) {
        let excludedList: any = [];
        excludedList = map(
          selectedRows.excludedRows,
          (value) => value?.original?.["gl_ref_id"]?.value?.["primary_value"]
        );

        if (tableObjRef.current) {
          let tableRows = tableObjRef.current.getSortedRowModel().rows;
          if (tableRows && tableRows.length > 0) {
            tableRows.some((r: any) => {
              if (
                excludedList.includes(
                  r.original?.["gl_ref_id"]?.value?.["primary_value"]
                )
              ) {
                r.toggleSelected(false);
              } else {
                r.toggleSelected(true);
              }
            });
          }
        }
      } else {
        let includedList: any = [];
        includedList = map(
          selectedRows.includedRows,
          (value) => value?.original?.["gl_ref_id"]?.value?.["primary_value"]
        );

        if (tableObjRef.current) {
          let tableRows = tableObjRef.current.getSortedRowModel().rows;
          if (tableRows && tableRows.length > 0) {
            tableRows.some((r: any) => {
              if (
                includedList.includes(
                  r.original?.["gl_ref_id"]?.value?.["primary_value"]
                )
              ) {
                r.toggleSelected(true);
              } else {
                r.toggleSelected(false);
              }
            });
          }
        }
      }
    }
  }, [JSON.stringify(selectedRows), chartData.chartData]);

  let forImpactReport = chartData.forReport || false;
  const pinned = meta_data?.chart?.["pinned"] || {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };
  const extraProps = chartData.extraProps || {};
  const [newID, setNewID] = useState("");

  const renderTableCell = (type: string) => {
    switch (type) {
      case "string-value":
        return CustomTableCell;

      case "structured":
        return CustomRichTextCell;
      case "range":
        return CustomRangeCell;
      case "hyperlink":
        return CustomHyperLinkCell;
      case "month-calendar":
        return CustomMonthCalanderCell;
      case "attachment":
        return CustomAttachmentsCell;
      default:
        return CustomTableCell;
    }
  };

  const createColumns = (r: any) => {
    let _obj = omit(r, columnsToBeHidden);
    let arr = Object.entries(_obj).map(([key, value]) => {
      return { key: key, position: value?.["position"] || 1 };
    });
    let orderedData = orderBy(arr, ["position"], ["asc"]).map(
      (el) => el["key"]
    );

    return [
      {
        header: " ",
        columns: map(orderedData, (value, key) => {
          return {
            accessorKey: value,
            header: ({ table }: any) => {
              return isEmpty(r[value]["info"]) ? (
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Tooltip
                    title={(
                      (r[value]?.["label"] && r[value]?.["label"]["value"]) ||
                      value
                    ).toUpperCase()}
                  >
                    <span>
                      {((r[value]?.["label"] && r[value]?.["label"]["value"]) ||
                      (value && value === "particulars")
                        ? " "
                        : value
                      ).toUpperCase()}
                    </span>
                  </Tooltip>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: 8 }}>
                    {(
                      (r[value]?.["label"] && r[value]?.["label"]["value"]) ||
                      value
                    ).toUpperCase()}
                  </span>
                  <span style={{ marginBottom: 2 }}>
                    <CustomPopover
                      key={value}
                      snippet={r[value]?.["info"]?.["snippet"] || ""}
                    />
                  </span>
                </div>
              );
            },
            cell: ({ row, getValue, cell }: any) => {
              let val = getValue();

              let TCell: any = renderTableCell(val?.["type"] || "string-value");
              return (
                <TCell
                  row={row}
                  cellValue={val}
                  cell={cell}
                  isReport={forImpactReport}
                  onCellClick={onDataPointClick}
                  onCellUpdate={onUpdateCallback}
                  onAttachmentClick={onAttachmentClick}
                  handleIgnoreList={handleIgnoreList}
                  ignoredAttachments={chartData?.ignoredAttachments}
                  extraProps={extraProps}
                />
              );
            },
          };
        }),
      },
    ];
  };
  let columns: any = React.useMemo(
    () =>
      enableRowSelection
        ? [
            {
              id: "select",
              header: ({ table }: any) => (
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    type: "select-all",
                    tableRef: table,
                  }}
                />
              ),
              cell: ({ row, table }: any) => (
                <div className="px-1">
                  <IndeterminateCheckbox
                    {...{
                      checked: row.getIsSelected(),
                      disabled: !row.getCanSelect(),
                      indeterminate: row.getIsSomeSelected(),
                      type: "select-single",
                      rowRef: row,
                      tableRef: table,
                    }}
                  />
                </div>
              ),
            },
            ...createColumns(kpiData[0]),
          ]
        : createColumns(kpiData[0]),
    [kpiData, chartData?.ignoredAttachments]
  );

  const data = React.useMemo(() => (kpiData ? kpiData : []), [kpiData, chartData?.ignoredAttachments]);

  return (
    <div className="datatable_wrapper">
      <DataTable
        enableRowSelection={true}
        isFullscreen={chartData.isFullscreen || false}
        setFullscreen={() => {
          if (chartData.setFullscreen) {
            return chartData.setFullscreen(false);
          }
        }}
        collapsed={meta_data?.chart?.collapsed || false}
        columns={columns}
        data={data}
        onRender={chartData?.onRender}
        forImpactReport={forImpactReport}
        pinned={pinned}
        onRowsSelection={onRowsSelection}
        getTableRef={getTableReference}
      />
    </div>
  );
};

export default memo(DataTableMolecule);
