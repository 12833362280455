import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  IconButton,
  Slider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DownloadIcon from "../../../assets/fi-rr-download 1.png";
import {
  createPublicReportPdf,
  createReportPdf,
} from "../../../services/impact-report-service";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../assets/fi-rr-arrow-left 1.png";
import LinkIcon from "../../../assets/link_icon.png";
import ZoomIcon from "../../../assets/zoom.png";
import ZoomIconLight from "../../../assets/zoom_icon_light.png";

import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import PresentationSwitch from "../../atoms/PresentationSwitch";
import { useMediaAndOrientation } from "../../../hooks/useMediaAndOrientation";

type Props = {
  reportName: string;
  reportId: any;
  onZoomChange: any;
  zoom: any;
  is_editable?: boolean;
  is_shareable?: boolean;
  isPublicManuallySet?: boolean;
  is_exportable?: boolean;
  publicReportId?: string;
  themeIsDark: boolean;
  setThemeIsDark: Function;
  mode?: string;
  fullScreenHandle: any;
  pages: number;
  isPresentation?:boolean;
};

function ImpactReportPresentationToolbar({
  reportName,
  reportId,
  onZoomChange,
  zoom,
  is_editable,
  isPublicManuallySet,
  is_shareable,
  setThemeIsDark,
  themeIsDark,
  is_exportable,
  publicReportId,
  mode,
  fullScreenHandle,
  pages,
  isPresentation
}: Props) {
  const [isPrinting, setIsPrinting] = React.useState(false);
  const [_zoom, setZoom] = useState(zoom);

  const navigate = useNavigate();
  const updateZoom = (e: any) => {
    setZoom(e.target.value);
    onZoomChange(e.target.value);
  };

  const printPage = () => {
    if (!publicReportId) return;
    setIsPrinting(true);
    if (mode === "public") {
      createPublicReportPdf(publicReportId, pages)
        .then((resp) => {
          if (resp && resp.status === 200) {
            Object.assign(document.createElement("a"), {
              target: "_blank",
              href: URL.createObjectURL(resp.data),
            }).click();
          }
        })
        .finally(() => {
          setIsPrinting(false);
        });
    } else {
      createReportPdf(reportId, pages)
        .then((resp) => {
          if (resp && resp.status === 200) {
            Object.assign(document.createElement("a"), {
              target: "_blank",
              href: URL.createObjectURL(resp.data),
            }).click();
          }
        })
        .finally(() => {
          setIsPrinting(false);
        });
    }
  };
  const isPublicPresentation = isPresentation && mode==='public';
  const {isMobileScreen} = useMediaAndOrientation();

  const isMobileStyleForPresentation = isPublicPresentation && isMobileScreen;

  const dispatch = useAppDispatch();
  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"space-between"}
      direction={"row"}
      px={{
        xs: isPublicPresentation?  0 :2,
        md: 2
      }}
    >
      <Stack
        direction="row"
        alignItems={"center"}
        justifyItems={"space-between"}
      >
        <Box sx={{ mt: 0.7 }}>
          <img
            onClick={() => navigate(-1)}
            src={BackButton}
            alt="Back"
            style={{
              maxHeight: "20px",
              cursor: "pointer",
              position: "relative",
            }}
          ></img>
        </Box>
        {!isMobileStyleForPresentation && <Box ml={4}>
          <Stack spacing={2} direction="row" alignItems="center">
            <Slider
              size="small"
              min={0.1}
              sx={{
                color: themeIsDark ? "#fff" : "#010033",
                height: "7px",
                minWidth: "200px",
                ".MuiSlider-valueLabel": {
                  display: "none",
                },
              }}
              max={5}
              step={0.1}
              valueLabelDisplay="auto"
              value={zoom}
              onChange={updateZoom}
            />
            <img
              src={themeIsDark ? ZoomIcon : ZoomIconLight}
              alt="ZoomIcon"
              style={{
                maxHeight: "1.5em",
                color: themeIsDark ? "#fff" : "#010033",
                cursor: "pointer",
              }}
            ></img>
            <span style={{ color: themeIsDark ? "#fff" : "#010033" }}>
              {Math.round(zoom * 100)}%
            </span>
          </Stack>
        </Box>}
        <Box ml={2}>
          <IconButton
            onClick={
              fullScreenHandle.active
                ? fullScreenHandle.exit
                : fullScreenHandle.enter
            }
            sx={{
              display: {
                xs: isPublicPresentation ? 'none' : 'block',
                md: 'block',
              },
            }}
          >
            {fullScreenHandle.active ? (
              <FullscreenExitIcon
                sx={{
                  height: 40,
                  width: 40,
                  color: themeIsDark ? "#6E6E81" : "inherit",
                }}
              />
            ) : (
              <FullscreenIcon
                sx={{
                  height: 40,
                  width: 40,
                  color: themeIsDark ? "#6E6E81" : "inherit",
                }}
              />
            )}
          </IconButton>
        </Box>
      </Stack>

      <Grid item sx={{
          ...isMobileStyleForPresentation && {flexGrow:1}
        }}>
        <div>
          <Typography
            variant="inter_p_600_16"
            noWrap
            sx={{
              maxWidth: 200,
              marginTop: "5px",
              display: "block",
              color: themeIsDark ? "#fff" : "#010033",
            }}
          >
            {reportName}
          </Typography>
        </div>
      </Grid>
      <Grid item>
        <Stack direction="row" alignItems={"center"} justifyItems={"end"}>
          {!isMobileStyleForPresentation && <PresentationSwitch
            sx={{ mr: 5 }}
            checked={!themeIsDark}
            onChange={(e) => setThemeIsDark(!themeIsDark)}
          />}

          <Tooltip title={"Copy link to clipboard"}>
            <LoadingButton
              sx={{
                height: 1,
                minWidth: "40px",
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  new URL(window.location.href).href
                );
                dispatch(
                  showMessage({
                    message: "Link copied to clipboard!",
                    variant: "success",
                    extraProps: {
                      anchorOrigin: { vertical: "top", horizontal: "right" },
                    },
                  })
                );
              }}
            >
              <img
                src={LinkIcon}
                alt="Back"
                style={{
                  maxHeight: "25px",
                  cursor: "pointer",
                }}
              ></img>
            </LoadingButton>
          </Tooltip>

          {publicReportId && (
            <Tooltip
              title={
                is_exportable || mode === "public"
                  ? "Print Report"
                  : "You don't have access to this action"
              }
            >
              <LoadingButton
                loading={isPrinting}
                fullWidth
                sx={{ height: 1 }}
                onClick={() => {
                  if (is_exportable || mode === "public") {
                    printPage();
                  }
                }}
              >
                <img
                  src={DownloadIcon}
                  alt="Back"
                  style={{
                    maxHeight: "25px",
                    opacity: is_exportable || mode === "public" ? 1 : 0.5,
                    cursor: "pointer",
                    display: isPrinting ? "none" : "block",
                  }}
                ></img>
              </LoadingButton>
            </Tooltip>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default React.memo(ImpactReportPresentationToolbar);
